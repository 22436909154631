import React, { useEffect, useState } from "react";
import styles from "../styles/VerChequeo.module.css";
import { useNavigate, useParams } from "react-router-dom";
import TopWindowBar from "./TopWindowBar";
import {
  faCheck,
  faCheckCircle,
  faClose,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../styles/carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VerChequeo = () => {
  const { contrato, matricula } = useParams();
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const loading = toast.loading("Cargando...");
    axios
      .get(
        `../../api/contratos.php?contrato=${contrato}&matricula=${matricula}&tipo=2`
      )
      .then((res) => {
        toast.dismiss(loading);
        setData(res.data.data);
      });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <TopWindowBar
          icon={faEye}
          title={"Ver Chequeo " + contrato}
          backroute={-1}
        />
        {data ? (
          <>
            <div className={styles.topTableContainer}>
              <div className={styles.topTableRow}>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Fecha</th>
                      <th>Hora</th>
                      <th>Kilometros</th>
                      <th>Combustible</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Salida</b>
                      </td>
                      <td>
                        {new Date(data.DHSalidaF).toLocaleDateString("es-UY", {
                          timeZone: "America/Montevideo",
                        })}
                      </td>
                      <td>{data.DHSalida.substring(11, 19)}</td>
                      <td>{data.KMSSalida}</td>
                      <td>{data.CombSalida}</td>
                    </tr>
                    {data.Chequeado == "2" ? (
                      <>
                        <tr>
                          <td>
                            <b>Entrada</b>
                          </td>
                          <td>
                            {new Date(data.DHEntradaF).toLocaleDateString(
                              "es-UY",
                              {
                                timeZone: "America/Montevideo",
                              }
                            )}
                          </td>
                          <td>{data.DHEntrada.substring(11, 19)}</td>
                          <td>{data.KMSEntrada}</td>
                          <td>{data.CombEntrada}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Totales</b>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            {parseInt(data.KMSEntrada) -
                              parseInt(data.KMSSalida)}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>

                <table>
                  <thead>
                    <tr>
                      <th>Gato</th>
                      <th>Herramientas</th>
                      <th>Tazas</th>
                      <th>Bomberito</th>
                      <th>Baliza</th>
                      <th>Radio</th>
                      <th>Botiquín</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[0]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[0])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[1]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[1])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[2]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[2])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[3]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[3])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[4]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[4])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                      <td>
                        <p>
                          {parseInt(data.Detalle[5])
                            ? "Original"
                            : "Desmontable"}
                        </p>
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={parseInt(data.Detalle[6]) ? faCheck : faClose}
                          style={
                            parseInt(data.Detalle[6])
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.topTableRow}>
                {data["Chequeado"] == 2 ? (
                  ""
                ) : (
                  <button
                    className={styles.enviar}
                    onClick={() =>
                      navigate("/cerrarChequeo/" + contrato + "/" + matricula)
                    }
                  >
                    <FontAwesomeIcon
                      className={styles.icon}
                      icon={faCheckCircle}
                    />{" "}
                    Cerrar
                  </button>
                )}
              </div>
            </div>
            <div className={styles.carouselContainer}>
              <Carousel showThumbs={false} width={"50%"}>
                {data.Archivos.map((archivo, index) => {
                  return (
                    <div
                      key={index + "foto"}
                      onClick={() => {
                        window.open(
                          `https://checking.montevideorentacar.com/api/archivos.php?contrato=${contrato}&matricula=${matricula}&archivo=${archivo[0]}`,
                          "_blank"
                        );
                      }}
                    >
                      <img
                        src={`../../api/archivos.php?contrato=${contrato}&matricula=${matricula}&archivo=${archivo[0]}`}
                      />
                      <p className="legend">{archivo[1]}</p>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default VerChequeo;
